body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}