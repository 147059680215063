.header-wrapper {
    width: 100%;
    border-bottom: 3px solid #000000;
    padding: 15px 15px;
    text-align: left;
}

.header-container {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.link {
    text-decoration: none;
    color: #000000;
}

.name {
    font-size: 20px;
    text-decoration: none;
}