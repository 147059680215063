.wrapper-slider {
    position: relative;
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
}

.image {
    width: 100%
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.3);
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

.imagce {
    display: none;
}

.display-image {
    width: 100%;
    display: block
}