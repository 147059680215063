.wrapper-homepage {
  margin: 1.5em auto;
  max-width: 1300px;
  column-gap: 0.5em;
}

.container-homepage {
  padding: 0 1em;
}

/* Masonry on large screens */
@media only screen and (min-width: 1024px) {
  .wrapper-homepage {
    column-count: 4;
  }
}

/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .wrapper-homepage {
    column-count: 3;
  }
}

/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
  .wrapper-homepage {
    column-count: 2;
  }
}